.button {
  padding: 1rem 2rem;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  font-weight: 800;
  transition: all ease 0.3s;
  display: flex;
  font-size: 20px;
  align-items: center;
}

.button.dark {
  background-color: #375042;
  color: #fff;
  filter: drop-shadow(#b0e2c3 0px 0px 10px);
}

.button.light {
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
}

.button.orange {
  background-color: orange;
  color: #fff;
}

.imageBlock {
  margin-right: 0.5rem;
}
