.banner {
  background-color: #e6f7eb;
  padding: 3rem 1rem;
  position: relative;
  display: flex;
}

.banner-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-images {
  display: flex;
  margin-bottom: 16px;
}

.profile-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  margin-right: -16px;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-text {
  text-align: center;
  margin-bottom: 16px;
}

.banner-text h3 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  max-width: 500px;
  text-align: center;
}

.join-button {
  background-color: #25d366;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.join-button:hover {
  background-color: #128c7e;
}

@media (min-width: 640px) {
  .banner-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .profile-images {
    margin-bottom: 0;
  }

  .banner-text {
    text-align: left;
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
  }
}
