.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 30;
  background-color: white;
  transition: transform 0.3s, top 0.8s;
}

.navbar.without-banner {
  top: 0;
}

.navbar.hidden {
  transform: translateY(-100%);
}

.navbar.bottomShadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo img {
  max-width: 200px;
}

.nav-items {
  display: flex;
  align-items: center;
}

.nav-items > * {
  margin-left: 1.5rem;
}

.nav-items a,
.nav-items button {
  color: #333;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: color 0.3s, border-bottom 0.3s;
  border-bottom: 2px solid transparent;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.nav-items a.active,
.nav-items button.active {
  color: #375042;
  border-bottom: 2px solid #375042;
}

.getStarted {
  background-color: #375042;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Sub-navbar */
.sub-navbar {
  background-color: #e6f7eb;
  display: flex;
  justify-content: center;
  width: 100%;
}

.sub-navbar a {
  margin: 0 1rem;
  color: #333;
  text-decoration: none;
  padding: 0.5rem 0;
  transition: color 0.3s, border-bottom 0.3s;
  border-bottom: 2px solid transparent;
}

.sub-navbar a.active {
  color: #375042;
  border-bottom: 2px solid #375042;
}

/* User Menu */
.user-menu {
  position: relative;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 120px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.user-menu:hover .dropdown-content {
  display: block;
}

.dropdown-content a,
.dropdown-content button {
  display: block;
  padding: 0.5rem;
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
}

/* Hamburger Menu for Mobile */
.hamburger {
  display: none;
}

@media (max-width: 768px) {
  .nav-items,
  .sub-navbar {
    display: none; /* Hide nav-items and sub-navbar on mobile */
  }

  .hamburger {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
  }

  .hamburger span {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px 0;
    background-color: #333;
  }

  /* Mobile Menu Styles */
  .mobile-menu {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 250px;
    background-color: white;
    padding: 1rem;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    z-index: 1000;
  }

  .mobile-menu.open {
    display: block;
  }

  .mobile-menu > * {
    display: block;
    margin: 1rem 0;
  }

  .mobile-menu a,
  .mobile-menu button {
    color: #333;
    text-decoration: none;
    padding: 0.5rem 0;
    transition: color 0.3s;
    border: none;
    background: none;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    text-align: left;
  }

  .mobile-menu a.active,
  .mobile-menu button.active {
    color: #375042;
    font-weight: bold;
  }

  .mobile-menu .submenu {
    margin-left: 1rem;
  }

  .mobile-menu .submenu a {
    font-size: 0.9rem;
  }
}
