.sipCalculator,
.lumpSumCalculator,
.roiCalculator {
  margin: 15rem auto;
  padding: 1rem;
  border: 1px solid #b0e2c3;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
  max-width: 800px;
}
.rangeInputGroup {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.labelTag {
  color: #193428;
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  text-align: left;
}

.inputSection {
  width: 100%;
}

.inputGroup {
  margin-bottom: 15px;
}

.inputGroup input[type="range"],
.inputGroup input[type="number"] {
  width: 100%;
  margin-bottom: 10px;
  border: none;
}

.inputGroup input[type="number"] {
  width: 25%;
  background: #b0e2c3;
  font-weight: 800;
  padding: 0.25rem;
  border-radius: 4px;
}

.inputGroup input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  background: #4caf50;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.inputGroup input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  background: #b0e2c3;
  border: none;
  border-radius: 4px;
}

.inputGroup input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 40px;
  background: #193428;
  cursor: pointer;
  border-radius: 50%;
  margin-top: -12px;
}

.inputGroup input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8px;
  background: #4caf50;
  border: none;
  border-radius: 4px;
}

.inputGroup input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #f44336;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #4caf50;
}

.inputGroup input[type="range"]::-ms-track {
  width: 100%;
  height: 8px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.inputGroup input[type="range"]::-ms-fill-lower {
  background: #4caf50;
  border-radius: 4px;
}

.inputGroup input[type="range"]::-ms-fill-upper {
  background: #4caf50;
  border-radius: 4px;
}

.inputGroup input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #f44336;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #4caf50;
}

.inputGroup input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #4caf50;
}

.inputGroup div {
  margin-top: 5px;
}

.resultSection {
  padding: 1rem 0;
  background-color: #fff;
  /* width: 50%; */
  border-radius: 16px;
  display: flex;
  gap: 1rem;
  text-align: left;
  justify-content: space-between;
}

.resultSection div {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.circularGraph {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.circular-chart {
  max-width: 100px;
  max-height: 100px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.circle.invested {
  stroke: #4caf50;
}

.circle.returns {
  stroke: #2196f3;
}

.graphLabels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
  padding: 10px;
}

.investedLabel {
  color: #4caf50;
  font-size: 20px;
  font-weight: 600;
}

.returnsLabel {
  color: #2196f3;
  font-size: 20px;
  font-weight: 600;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.inputSymbol {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.25rem 0.75rem;
}

.amountsLabel {
  font-size: 14px;
  line-height: 1.25rem;
  letter-spacing: 0;
  font-weight: 500;
}

.amountValue {
  font-size: 16px;
  line-height: 1.25rem;
  letter-spacing: 0;
  font-weight: 800;
}

.calcTypeButton {
  background: #193428;
  width: 40%;
  font-size: 16px;
  font-weight: 500;
}
.calcTypeButton:hover {
  background: #193428;
}

/* calculators.css */

.calculator-container {
  padding: 20px;
  margin: 0 auto;
  max-width: 80%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.investment-mode {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.investment-mode button {
  flex: 1;
  margin: 0 5px;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
}

.amount-input {
  margin-bottom: 20px;
  text-align: center;
}

.amount-input label {
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.amount-input input[type="range"] {
  width: 100%;
  margin-top: 10px;
  -webkit-appearance: none;
  appearance: none;
  background: #ddd;
  height: 6px;
  border-radius: 3px;
  outline: none;
}

.amount-input input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #4caf50;
  cursor: pointer;
  border-radius: 50%;
}

.amount-input input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #4caf50;
  cursor: pointer;
  border-radius: 50%;
}

.time-period {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.time-period button {
  flex: 1;
  margin: 0 5px;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
}

.result-display {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.result-display p {
  margin: 5px 0;
  color: #333;
}

.result-display .positive {
  color: #4caf50;
  font-size: 16px;
  font-weight: 500;
}

.result-display .negative {
  color: #f44336;
  font-size: 16px;
  font-weight: 500;
}

.btn-success {
  background: #193428;
}
