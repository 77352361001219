.kycStatusBanner {
  position: relative;
  cursor: pointer;
}

.closeButtonKYC {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  color: inherit;
}
