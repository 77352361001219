.bannerSection {
  position: relative;
  display: flex;
  /* height: calc(var(--vh, 1vh) * 90); */
  height: 90vh;
  background: #fff;
}

/* @media screen and (max-width: 600px) {
  .bannerSection {
    height: calc(var(--vh, 1vh) * 125);
  }
} */

/* @media screen and (max-width: 380px) {
  .bannerSection {
    height: calc(var(--vh, 1vh) * 125);
  }
} */

/* @media screen and (max-width: 370px) {
  .bannerSection {
    height: calc(var(--vh, 1vh) * 115);
  }
} */

/* Safari specific styles */
/* .safari.bannerSection {
  height: calc(var(--vh-safari, 1vh) * 145);
} */

@media screen and (max-width: 600px) {
  .safari.bannerSection {
    height: calc(var(--vh-safari, 1vh) * 105);
  }
}

@media screen and (max-width: 380px) {
  .safari.bannerSection {
    height: calc(var(--vh-safari, 1vh) * 145);
  }
}

@media screen and (max-width: 370px) {
  .safari.bannerSection {
    height: calc(var(--vh-safari, 1vh) * 100);
  }
}

.bannerContentSection {
  z-index: 20;
  display: flex;
  padding-left: 6%;
  justify-content: flex-start;
  align-items: center;
  max-width: 45%;
  margin-top: 2rem;
}

@media screen and (min-width: 769px) {
  .bannerContentSection {
    max-width: 100%;
  }
}

.bannerAnimationDesktop {
  display: flex;
  z-index: 1;
  justify-content: center;
}

.bannerAnimationMobile {
  display: none;
}

@media screen and (max-width: 760px) {
  .bannerAnimationDesktop {
    display: none;
  }
  .bannerAnimationMobile {
    display: flex;
    position: absolute;
    bottom: 0;
  }
  .bannerAnimationMobile svg {
    width: 100vw !important;
  }
}

.bannerAnimationDesktop svg {
  height: calc(var(--vh, 1vh) * 120) !important;
  z-index: -1;
}

.caption {
  text-align: center;
}

.mainBannerHeading {
  font-size: 3rem;
  font-weight: 800;
  line-height: 1.2;
  color: #193428;
  letter-spacing: 0px;
  text-align: left;
}

.subHeading {
  font-size: 20px;
  color: #416052;
  width: 100%;
  max-width: 50vw;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;
}

.bannerButton {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}

@media screen and (max-width: 360px) {
  .mainBannerHeading {
    font-size: 1.75rem;
    margin-top: 0rem;
  }
}
@media screen and (max-width: 768px) {
  .mainBannerHeading {
    font-size: 1.75rem;
    margin-top: -15rem;
  }
  .subHeading {
    width: 100%;
    font-size: 16px;
    max-width: 100vw;
  }
  .bannerButton {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
  }
}

.goToProfileButton {
  padding: 1rem;
  background: #193428;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-size: 20px;
  color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-weight: 800;
}
