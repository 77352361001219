.blogTitleSection {
  padding: 8rem 0;
  background: #b0e2c3;
  width: 100%;
}

.pageTitle {
  font-size: 3rem !important;
  color: #193428;
  font-weight: 800;
}

.blogCardListComponent {
  padding: 2.5rem 0;
}

@media screen and (max-width: 768px) {
  .blogCardListComponent {
    padding: 2rem 1rem;
  }
}
/* Staggered effect */
.blog-card {
  margin-bottom: 30px;
}

.blog-card.offset-card {
  margin-top: 60px; /* Adjust this value to create the desired offset effect */
}
