.formComponent {
  padding: 1rem;
}

.formInputItem {
  margin-bottom: 0.5rem;
}
.formHeading {
  font-size: 2rem;
  font-weight: 800;
  color: #193428;
}
.submitButton {
  border: none;
  width: 100%;
  border-radius: 8px;
  padding: 1rem 2rem;
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
  color: #fff;
  background-color: #193428;
}
.submitButton:hover {
  background-color: #193428;
}
.submitButton:disabled {
  cursor: not-allowed;
  background: #e0e0e0;
}

.form-control {
  border: none;
  background: #ecf3f0;
  border-radius: 10px;
  height: 60px;
  margin: 0.25rem 0;
  /* width: 80%; */
  border: none;
  font-size: 1rem;
  /* margin: 0 0 1rem; */
  font-weight: 600;
  padding: 0.5rem;
}
.formSelect {
  background: red;
}

.form-label {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}

.asterisk {
  color: red;
}
.invalid-feedback {
  margin: 0;
  font-weight: 600;
}

.googleButton {
  padding: 0.5rem 0;
  border-radius: 4px;
  display: flex;
  gap: 1rem;
  cursor: pointer;
  width: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}
@media screen and (max-width: 768px) {
  .googleButton {
    width: 100%;
  }
}

.toSignIn {
  font-size: 20px;
  color: #193428;
  font-weight: 800;
  cursor: pointer;
}

.fgPass {
  color: #193428;
  font-weight: 600;
  /* right: 10px; */
  position: absolute;
}

.whatsappCheckboxText label {
  font-size: 12px;
}
