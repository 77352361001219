.company-detail-page {
  margin: 5rem auto;
  padding: 3rem 0;
}

.company-logo {
  max-width: 100%;
  height: auto;
}

.investment-overview {
  background-color: #f8f9fa;
  border-radius: 8px;
}

.overview-details p {
  margin-bottom: 0.5rem;
}
.overview-details {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.banner-img {
  border-radius: 8px 0 0 8px;
}

/* Custom Tabs */
.custom-tabs {
  overflow-x: auto;
  margin-top: 20px;
}

.tab-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  border-bottom: 2px solid #ddd;
}

.tab-item {
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease-in-out;
  font-weight: bold;
}

.tab-item:hover,
.tab-item.active {
  color: #000;
}

.tab-item.active::before,
.tab-item:hover::before {
  content: "";
  position: absolute;
  bottom: -2px;
  height: 4px;
  width: 0;
  background-color: #b0e2c3;
  transition: width 0.3s ease, left 0.3s ease;
}

.tab-item:hover::before {
  width: 100%;
}

.tab-item[data-hovered-from="left"]::before {
  left: 0;
}

.tab-item[data-hovered-from="right"]::before {
  left: auto;
  right: 0;
}

.tab-item.active::before {
  width: 100%;
  left: 0;
}

/* Tab Content */
.tab-content {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: -1px; /* Aligns with tab bottom border */
}

/* Fixed buttons */
.fixed-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  padding: 2rem;
}

.fixed-buttons button {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.fixed-buttons button:hover {
  transform: scale(1.1);
}

.interested-button,
.shortlist-button {
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #000 !important;
  transition: all 0.3s ease;
  border-radius: 8px;
  border: none;
}
