.stockBundles {
  padding: 4rem 1rem;
  background: #ecf3f0;
}
.mainHeading {
  color: #193428;
}

@media screen and (max-width: 1080px) {
  .fundsCardRow {
    gap: 2rem;
  }
  .stockBundles {
    padding: 2rem 0.25rem;
  }
}
